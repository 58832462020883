import './contact.css' 

import {useState} from 'react';

import badBoy from '../../assets/BadBoy.svg'
import questionMark from '../../assets/questionMark.svg'

import { TbBrandMailgun, TbMailbox, TbMail} from 'react-icons/tb';
import { TbWindsock, TbPinned, TbPennant, TbMapPin, TbGolf, TbFlag3 } from 'react-icons/tb';
import ReactPlayer from "react-player";

import { ParallaxProvider, Parallax } from 'react-scroll-parallax';

import pageContent from "../../content";
const {contact} = pageContent.coreComponents;


const YoutubeEmbed = ({ embedId }) => (
    <div className="screen">
      <ReactPlayer
        url={`https://www.youtube.com/embed/${embedId}`}
        playing={true}
        style={{position: "absolute", left: -40}}
        width={"153%"}
        height={"97%"}
    />
    </div>
  );


const emailIco = [TbMail, TbBrandMailgun, TbMailbox];
const addressIco = [TbWindsock, TbPinned, TbPennant, TbMapPin, TbGolf, TbFlag3];

function randomIcon(icons) {
    const randomIndex = Math.floor(Math.random() * icons.length);
    const Icon = icons[randomIndex];
    return <Icon />;
  }

const Contact = () => {
const { title, paragraph, email, address } = contact;

const [isVideoPlaying, setIsVideoPlaying] = useState(false);

const handlePlayVideo = () => {
  setIsVideoPlaying(true);
};

return (
    <ParallaxProvider>
    <div id='contact' className="funtenjer">
            <Parallax translateY={[-20, 10]}>
        <section className="contact">
            <div className="info">
                <h2>{title}</h2>
                <p>{paragraph}</p>
                <div className="items-holder">
                    <div className="item">
                        <div className="left">
                            {randomIcon(emailIco)}
                        </div>
                        <div className="right">
                            <div>Email</div>
                            <span>{email}</span>
                        </div>
                    </div>
                    <div className="item">
                        <div className="left">
                            {randomIcon(addressIco)}
                        </div>
                        <div className="right">
                            <div>Address</div>
                            <span>{address}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="img-holder">
                <img className='question' src={questionMark} alt="questions"></img>
                
                    <img className='main-image' src={badBoy} alt=""></img>
                    {!isVideoPlaying && (
                    <div className='screen-btn' onClick={handlePlayVideo}></div>
                     )}
                    {isVideoPlaying && (
                    <YoutubeEmbed embedId="dQw4w9WgXcQ"/>
                    )}
                
            </div>
        </section>
            </Parallax>
    </div>
    </ParallaxProvider>
  )
}

export default Contact