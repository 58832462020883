import './projectInfo.css';
import React, { useState } from "react";

const ProjectInfo = ({time, type, industry, platform}) => {
  return (
    <div className='project-info'>
        <div className='info'>
            <span className='title'>Integration time:</span>
            <span className='description'>{time}</span>
        </div>
        <div className='info'>
            <span className='title'>Type:</span>
            <span className='description'>{type}</span>
        </div>
        <div className='info'>
            <span className='title'>Industry:</span>
            <span className='description'>{industry}</span>
        </div>
        <div className='info'>
            <span className='title'>Platform:</span>
            <span className='description'>{platform}</span>
        </div>
    </div>
  )
}

export default ProjectInfo;
