import { Link, useLocation } from 'react-router-dom';

function Navigation() {
  const location = useLocation();

  return (
    <ul>
      <li className={location.pathname === '/' ? 'active' : ''}>
        <Link to="/">Home</Link>
      </li>
      <li className={location.pathname === '/about' ? 'active' : ''}>
        <Link to="/about">About Us</Link>
      </li>
      <li className={location.pathname === '/eLibrary' ? 'active' : ''}>
        <Link to="/eLibrary">eLibrary</Link>
      </li>
      <li className={location.pathname === '/nanodegreeProgram' ? 'active' : ''}>
        <Link to="/nanodegreeProgram">Nanodegree</Link>
      </li>
    </ul>
  );
}

export default Navigation;