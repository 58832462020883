import React from "react";
import PropTypes from 'prop-types';

import ProgressiveImage from 'react-progressive-graceful-image';

import './projectFeatures.css';

function iOS() {
  return [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod'
  ].includes(navigator.platform) && "ontouchend" in document;
}


const Feature = ({ title, description, img, minImg}) => {
    return (
        <div className="feature">
        <div className="info">
            <h3>{title}</h3>
            <p>{description}</p>
        </div>
        <div className="img-holder">
            <ProgressiveImage src={img} placeholder={minImg}>
              {(src) => <img src={src}  alt="an image" />}
            </ProgressiveImage>
        </div>
    </div>
    )
  }
  
 Feature.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    img: PropTypes.string.isRequired,
    minImg: PropTypes.string
  }

const ProjectFeatures = ({features, featuresDescription, background, minBackground}) => {
  return (
    <section className='project-features'>
      <div className="about-features">
      <h2>Key features</h2>
      <p>{featuresDescription}</p>
      </div>
            {features.map((feature, index)=> (
                <Feature key={index} title={feature.title} description={feature.description} img={feature.img} minImg={feature.minImg}/>
            ))}
      <ProgressiveImage src={background} placeholder={minBackground}>
        {(src) => <div style={{backgroundImage: `url(${src})`}} className="project-image"></div>}
      </ProgressiveImage>
    </section>
  )
}

ProjectFeatures.propTypes = {
    features: PropTypes.array.isRequired
}

export default ProjectFeatures;