import './hero.css'
// Images
import image1 from '../../assets/image1.svg'
import BackEndCode from '../../assets/back-end-code.svg'
import FrontEndCode from '../../assets/front-end-code.svg'
// Components
import Button from '../button/Button'
// Libraries
import Marquee from "react-fast-marquee";
import { ParallaxProvider, Parallax } from 'react-scroll-parallax';
const Hero = ({title, paragraph, callToAction , words}) => {
  return (
    <ParallaxProvider>
    <section className='hero'>
      <div className="big-funtenjer">
      <section className="grid">
          <div className="info">
            <h1>{title}</h1>
            <p>{paragraph}</p>
            <div className="button-holder">
              <Button type="primary-light">{callToAction}</Button>
            </div>
            <Parallax easing='easeOutQuad' speed={5}>
            <div className="picture">
              <img src={image1} alt=''></img>
            </div>
            </Parallax>
          </div>
          <Parallax speed={-10}>
          <div className='presentation'>
            <div className="slide">
              <img src={FrontEndCode} alt="" />
            </div>
            <div className="slide">
              <img src={BackEndCode} alt="" />
            </div>
          </div>
            </Parallax>
      </section>
    </div>
    <div className='roll-content'>
      <Marquee speed={30} loop={0} gradient={false}>
        {words.map((word, index) => (
                <Button key={index} type="secondary-light">{word}</Button>
              ))}
      </Marquee>
    </div>
  </section>
  </ParallaxProvider>
  )
}

export default Hero