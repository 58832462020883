import './footer.css';
import Logo from '../logo/Logo';
import { FaFacebookF } from 'react-icons/fa';
import { FaInstagram } from 'react-icons/fa';
import { FaLinkedinIn } from 'react-icons/fa';

import { Link } from 'react-router-dom';

import pageContent from "../../content";
const {footer} = pageContent.coreComponents;

const Footer = () => {
    const {facebook, instagram, linkedIn} = footer;
  return (
    <div className="funtenjer">
    <footer>
        <div className='grid'>
            <div className="socials">
                <Link to='/' className="logo">
                    <Logo/>
                </Link>
                <div className="holder">
                    <Link to={facebook} className="item" target="_blank">
                        <FaFacebookF/>
                    </Link>
                    <Link to={instagram} className="item" target="_blank">
                        <FaInstagram/>
                    </Link>
                    <Link to={linkedIn} className="item" target="_blank">
                        <FaLinkedinIn/>
                    </Link>
                </div>
            </div>
            <div className="navigation">
                <div className="column">
                    <h3>Resources</h3>
                    <ul>
                        <li><Link to="/PrivacyPolicy">Privacy Policy</Link></li>
                    </ul>
                </div>
                    <div className="column">
                        <h3>About</h3>
                        <ul>
                            <li><a href='/about#our-mission'>Our Mission</a></li>
                            <li><a href='/about#we-are-rouzel'>Who we are</a></li>
                        </ul>
                    </div>
                </div>
        </div>
        <div className='brief'>
            <p>© Copyright {new Date().getFullYear()}, Rouzel LTD. All rights reserved.</p>
        </div>
    </footer>
    </div>
  )
}

export default Footer