import './note.css';
import { FaQuoteLeft } from 'react-icons/fa';
import Heart from 'react-heart';
import { memo, useCallback, useEffect, useState } from 'react';
import Cookies from 'js-cookie';

import { database } from '../../config/firebase'
import { getDocs, updateDoc, collection, doc } from "firebase/firestore";

// Memoized function to prevent unnecessary re-renders
const useGetCount = (counterCollectionRef) => {
  const getCount = useCallback(async () => {
    if (window.location.hostname === "localhost" && window.location.port === "3000") {
      return Math.floor(Math.random() * 100);
    }else{
    try {
      const data = await getDocs(counterCollectionRef);
      const filteredData = data.docs.map((doc) => ({ ...doc.data() }));
      return filteredData[0].count;
    } catch (ex) {
      console.log("Error", ex);
    }
  }
  }, [counterCollectionRef]);

  return getCount;
};

const Note = memo(({ highlighted, bookName, bookAuthor, color }) => {
  const counterCollectionRef = collection(database, "counter");
  
  // Call the memoized function
  const getCount = useGetCount(counterCollectionRef);

  const [active, setActive] = useState(false);
  const [count, setCount] = useState("...");

  useEffect(() => {
    getCount().then((count) => setCount(count || 1));
  }, [getCount]);

  const updateCounter = async (number) => {
    const counterDoc = doc(database, "counter", "d6QFU3IIz7efnwdrXJQi");
    await updateDoc(counterDoc,{count: count + number});
  }

  useEffect(() => {
    try {
      const savedState = JSON.parse(Cookies.get(`note_${highlighted}`));
      if (savedState) { 
        setActive(savedState.active);
      }
    } catch (error) {
      console.error('Error parsing JSON:', error);
    }
  }, [highlighted]);
  
  const handleClick = () => {
    active ? updateCounter(-1) : updateCounter(1);
    const newActive = !active;
    setActive(newActive);
    setCount(count + (newActive ? 1 : -1));
    Cookies.set(`note_${highlighted}`, JSON.stringify({ active: newActive }), { expires: 9999 });
  };

  return (
    <div className="note">
      <div className="ico-holder">
        <FaQuoteLeft />
      </div>
      <div className="text-holder">
        <mark style={{ backgroundColor: color }}>{highlighted}</mark>
      </div>
      <div className="note-footer">
        <div className="book-info">
          <span>-{bookName}, </span>
          <span>{bookAuthor}</span>
        </div>
        <div className="heart-holder">
          <div>
            <Heart
              className="heart"
              isActive={active}
              inactiveColor={'var(--secondary-color)'}
              activeColor={'var(--secondary-color)'}
              onClick={handleClick}
              style={{ width: '1.3em', color: active ? 'var(--secondary-color)' : 'var(--primary-color)' }}
            />
          </div>
          <span>{count}</span>
        </div>
      </div>
    </div>
  );
});

export default Note;
