import React from "react";

import "./projectResult.css";

const ProjectResult = ({description, img})=>{
    return(
        <section className="project-result">
            <div className="funtenjer">
                <div className="section-description">
                    <h2>Results</h2>
                    <p>{description}</p>
                </div>
                <div className="project">
                    <img src={img}></img>
                </div>
            </div>
        </section>
    );
}

export default ProjectResult;