import { Link } from 'react-router-dom';

const Button = ({link, type, children, onClick }) => {
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  }

  return (
    <div onClick={handleClick}>
      <Link
        to={link}
        className={type + "-btn"}
      >
        {children}
      </Link>
    </div>
  )
};

Button.defaultProps = {
  link: '#'
}

export default Button;
