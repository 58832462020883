import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyBJjZd_v1TpqUGKRsaluzF_9wizrZxt88I",
    authDomain: "rouzel-d67b0.firebaseapp.com",
    databaseURL: "https://rouzel-d67b0-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "rouzel-d67b0",
    storageBucket: "rouzel-d67b0.appspot.com",
    messagingSenderId: "758123046253",
    appId: "1:758123046253:web:6076982fa2681971e7e664",
    measurementId: "G-9P78YDX8LE"
};

export const app = initializeApp(firebaseConfig);
export const database = getFirestore(app);