import "./css/main.css"
import "./css/privacyPolicy.css"

import {motion as m} from "framer-motion"

import Header from './components/header/Header';
import Footer from './components/footer/Footer';

function PrivacyPolicy() {
  return (
        <m.div  initial={{opacity: 0}} animate={{opacity: 1}} transition={{durtation:1, ease: "easeOut"}} exit={{opacity: 0}}>
          <Header/>
            <section className="privacy-policy funtenjer">
                <div className="header">
                    <h1>Rouzel Privacy Policy</h1>
                    <p>Last Updated: May 17, 2023</p>
                </div>
                <div className="content">
                    <h2>Introduction</h2>
                    <p>Welcome to Rouzel. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website Rouzel (the "Website"). Please read this Privacy Policy carefully. If you do not agree with the terms of this Privacy Policy, please do not access the Website.</p>
                    <p>By accessing or using the Website, you agree to this Privacy Policy. This Privacy Policy may change from time to time. Your continued use of the Website after we make changes is deemed to be acceptance of those changes, so please check the Privacy Policy periodically for updates.</p>
                    <h2>Data We Collect</h2>
                    <p>Our Website uses cookies to enhance User experience. User's web browser places cookies on their hard drive for record-keeping purposes and sometimes to track information about them. User may choose to set their web browser to refuse cookies, or to alert you when cookies are being sent. If they do so, note that some parts of the Site may not function properly.</p>
                    <p>We use Google Analytics to help us understand how our customers use the Site. You can read more about how Google uses your Personal Information <a href="https://policies.google.com/privacy?hl=en">here</a>. You can also opt-out of Google Analytics <a href="https://tools.google.com/dlpage/gaoptout">here</a>.</p>
                    <h2>How We Use Your Information</h2>
                    <p>We use the information we collect in various ways, including to:</p>
                    <ul>
                        <li>Improve, personalize, and expand our Website</li>
                        <li>Understand and analyze how you use our Website</li>
                        <li>Develop new products, services, features, and functionality</li>
                        <li>Communicate with you, either directly or through one of our partners, for customer service, to provide you with updates and other information relating to the Website, and for marketing and promotional purposes</li>
                    </ul>
                    <h2>How We Share Your Information</h2>
                    <p>We do not share or sell your personal data to third parties for marketing or advertising purposes.</p>
                    <h2>Keeping Your Information Safe</h2>
                    <p>We use administrative, technical, and physical security measures to help protect your personal information. While we have taken reasonable steps to secure the personal information you provide to us, please be aware that despite our efforts, no security measures are perfect or impenetrable, and no method of data transmission can be guaranteed against any interception or other type of misuse.</p>
                    <h2>Changes to this Policy</h2>
                    <p>We may update our Privacy Policy from time to time. Thus, you are advised to review this page periodically for any changes. We will notify you of any changes by posting the new Privacy Policy on this page. These changes are effective immediately after they are posted on this page.</p>
                    <h2>Contact Us</h2>
                    <p>If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us at <a href="mailto:rouzel.ltd@gmail.com">rouzel.ltd@gmail.com</a>.</p>
                    <p>By using the Website, you are hereby accepting the terms and conditions stipulated within the Privacy Policy Agreement. If you are not in agreement with our terms and conditions, then you should refrain from further use of our sites. In addition, your continued use of our website following the posting of any updates or changes to our terms and conditions shall mean that you agree and acceptance of such changes.</p>
                </div>
            </section>
          <Footer/>
          </m.div>
  );
}

export default PrivacyPolicy;
