import classNames from 'classnames';
//images
import eLibraryHome from '../../assets/eLibrary/bluePrints/home.svg';
import eLibraryNotes from '../../assets/eLibrary/bluePrints/notes.svg';
import eLibraryBooks from '../../assets/eLibrary/bluePrints/books.svg';
import eLibraryMyBooks from '../../assets/eLibrary/bluePrints/myBooks.svg';

import Note from '../note/Note';

import './eLibraryMockup.css';

import { useEffect, useState } from 'react';
// icons
import {TbHome} from 'react-icons/tb';
import {TbBook2} from 'react-icons/tb';
import {TbNote} from 'react-icons/tb';
import {TbBooks} from 'react-icons/tb';

const ELibraryMockup = () => {

  const [activeMockupTab, setActiveMockupTab] = useState(0);
  const [toFadeOut, setToFadeOut] = useState(false);

  const fadeAnimationDuration = 0.5; // seconds
  const autoIncrementMockupsInterval = 9000; // milliseconds

  const fadeAndThenSetActiveMockupTab = function(index){
    clearTimeout(currentAutoIncrementIntervalId);
    setToFadeOut(true);
    setTimeout(() => {  
      setActiveMockupTab(index);
      setToFadeOut(false);
    }, fadeAnimationDuration*1000);
  };

  const mockupTabs = [
    { icon: TbHome, mockupImage: eLibraryHome },
    { icon: TbBook2, mockupImage: eLibraryBooks },
    { icon: TbNote, mockupImage: eLibraryNotes },
    { icon: TbBooks, mockupImage: eLibraryMyBooks },
  ];

  let currentAutoIncrementIntervalId = null;

  function removeAutoIncrementInterval(){
    clearTimeout(currentAutoIncrementIntervalId);
  }

  function resetAutoIncrementInterval() {
    const currentTimeoutId = currentAutoIncrementIntervalId = setTimeout(() => {
      fadeAndThenSetActiveMockupTab((activeMockupTab + 1) % mockupTabs.length);
    }, autoIncrementMockupsInterval);
    return () => clearTimeout(currentTimeoutId);
  }

  useEffect( resetAutoIncrementInterval , [activeMockupTab]);

  return (
    <div className='img-holder' onMouseEnter={removeAutoIncrementInterval} onMouseLeave={resetAutoIncrementInterval} >
      <div className="note-holder">
        <Note highlighted={"The greatest victory is that which requires no battle."}
          color={"var(--primary-color-light)"}
          bookName={"Art of war"}
          bookAuthor={"San Tzu"}
        />
      </div>
      <img
        className={classNames('mockup-image', { fadein: !toFadeOut })}
        src={mockupTabs[activeMockupTab].mockupImage}
        style={{ "--fade-animation-duration": fadeAnimationDuration+'s' }}
        alt="mockup" />
      <div className="nav">
        { mockupTabs.map((tab, index) => (
          <div 
            className={ classNames('ico', {
                active: index === activeMockupTab
            })}
            key={index}
            onClick={() => fadeAndThenSetActiveMockupTab(index)}>
              <tab.icon />
          </div>
        )) }
      </div>
    </div>
  )
}

export default ELibraryMockup