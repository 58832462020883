import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import Logo from '../logo/Logo';
import Navigation from '../navigation/Navigation';
import { Squash as Hamburger } from 'hamburger-react'

import './header.css';

function useScrollDirection() {
  const [scrollDirection, setScrollDirection] = useState(null);

  useEffect(() => {
    let lastScrollY = window.pageYOffset;

    const updateScrollDirection = () => {
      const scrollY = window.pageYOffset;
      const direction = scrollY > lastScrollY ? "down" : "up";
      if (
        direction !== scrollDirection &&
        (scrollY - lastScrollY > 5 || scrollY - lastScrollY < -5)
      ) {
        setScrollDirection(direction);
      }
      lastScrollY = scrollY > 50 ? scrollY : 50;
    };

    window.addEventListener("scroll", updateScrollDirection);
    return () => {
      window.removeEventListener("scroll", updateScrollDirection);
    };
  }, [scrollDirection]);

  return scrollDirection;
}

const Header = () => {
  const scrollDirection = useScrollDirection();
  const [isOpen, setOpen] = useState(false);

  const closeMobileNav = () => {
    document.body.style.overflow = 'unset';
    setOpen(false);
  }

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [isOpen]);

  return (
    <>
    <header className={`${scrollDirection === "down" ? "hidden" : "visible"} big-funtenjer`} style={isOpen ? {background: "var(--body-color)"} : {background: "unset"}}>
      <nav>
        <Link to="/" className='logo'>
          <Logo />
        </Link>
        <div className="navigation">
          <Navigation />
        </div>
      </nav>
      <div className='button-holder'>
        <a href="#contact" className='primary-border-btn'>Contact Us</a>
      </div>
      <div className="hamburger">
        <Hamburger toggled={isOpen} toggle={setOpen} />
      </div>
    </header>
    {isOpen && (
        <div className="mobile-nav" onClick={() => closeMobileNav()}>
          <Navigation />
          <div className='button-holder'>
            <a href="#contact" className='primary-border-btn'>Contact Us</a>
          </div>
        </div>
      )}
    </>
  );
}

export default Header;
