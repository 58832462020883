import './aboutProject.css';
import React, { useState } from "react";
import PropTypes from 'prop-types';
import {TbCheck} from 'react-icons/tb';

const Idea = ({ title, description }) => {
  return (
    <div className="idea">
      <div className="header">
        <div className="circle">
          <TbCheck/>
        </div>
        <div className="title">
          {title}
        </div> 
      </div>
      <div className="body">
        {description}
      </div>
    </div>
  )
}

Idea.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired
}

const AboutProject = ({ ideas, shortDescriptoin }) => {
  return (
      <section className='about-project'>
        <div className='grid'>
          <div className='projects-ideas-holder'>
            <div className='project-ideas'>
              {ideas.map((idea, index) => (
                <Idea key={index} title={idea.title} description={idea.description} />
              ))}
            </div>
          </div>
          <div className='short-description-holder'>
            <div className="short-description">
              <h3>About</h3>
              <h2>Project idea</h2>
              <p>{shortDescriptoin}</p>
            </div>
          </div>
        </div>
      </section>
  )
}

AboutProject.propTypes = {
  ideas: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired
  })).isRequired
}

export default AboutProject;