import React, { useState} from 'react';

import logo from "../../assets/logo.svg";
import logo1 from "../../assets/logo1.svg";
import logo2 from "../../assets/logo2.svg";

const Logo = () => {
    const [active, setActive] = useState(false);
    const [currentImg, setCurrentImg] = useState(0);
    const images = [logo, logo1, logo2];
  
    const handleMouseEnter = () => {
      setActive(true);
      let i = 0;
  
      const intervalId = setInterval(() => {
        setCurrentImg((prev) => (prev + 1) % images.length);
        i++;
        if (i >= images.length) {
          clearInterval(intervalId);
          setActive(false);
        }
      }, 400);
    };
  
    return (
      <img
        src={images[currentImg]}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={() => setActive(false)}
      />
     );
  };

export default Logo;

