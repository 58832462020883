import './cookieBanner.css';

import {motion as m} from 'framer-motion';

import cookieSvg from '../../assets/cookies-icon.svg';

import React, { useState } from 'react';
import Cookies from 'js-cookie';
import { Link } from 'react-router-dom';

import Button from "../button/Button";


const CookieBanner = () => {
  const [accepted, setAccepted] = useState(Cookies.get('cookiesAccepted'));

  const acceptCookies = () => {
    Cookies.set('cookiesAccepted', 'true', { expires: 365 });
    setAccepted(true);
  };

  if (accepted) {
    return null;
  }

  return (
    <m.div initial={{opacity: 0}} animate={{opacity: 1}} transition={{durtation:5, ease: "easeInOut", delay:2}} exit={{opacity: 0}} className="cookie-banner">
      <div className="ico-holder">
        <img src={cookieSvg} alt="cookie icon" />
      </div>
      <div className='text'>
        <p>Hey there! We've got digital <Link to='/privacyPolicy'>cookies</Link> (unfortunately not the yummy kind). Click 'Accept' to make our website even more awesome!</p>
      </div>
      <div className="button-holder">
      <Button type="secondary" onClick={acceptCookies}>Accept</Button>
      </div>
    </m.div>
  );
};

export default CookieBanner;
