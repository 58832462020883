import './technologyStack.css';
import React from "react";

const TechnologyStack = ({technologies}) => {
  return (
    <section className='technology-stack'>
        <div className='header'>
            <h2>Technology stack</h2>
            <p>Here is the list of technologies and tools to bring our idea to life:</p>
        </div>
        <div className="grid">
          {technologies.map((technology, index) => (
            <div className="technology" key={index}>
              {technology.ico}
              <div className='title'>{technology.title}</div>
              <div className='name'>{technology.name}</div>
            </div>
          ))}
        </div>
    </section>
  )
}

export default TechnologyStack;
