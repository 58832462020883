import React from "react";
import { useLocation } from "react-router-dom";

import './slider.css'

import '../stacks/stacks.css'
import Button from '../button/Button'

// icons
import {TbArrowBackUp} from 'react-icons/tb';


function useScrollDirection() {
    const [scrollDirection, setScrollDirection] = React.useState(null);
  
    React.useEffect(() => {
      let lastScrollY = window.pageYOffset;
  
      const updateScrollDirection = () => {
        const scrollY = window.pageYOffset;
        const direction = scrollY > lastScrollY ? "down" : "up";
        if (direction !== scrollDirection && (scrollY - lastScrollY > 5 || scrollY - lastScrollY < -5)) {
          setScrollDirection(direction);
        }
        lastScrollY = scrollY > 50 ? scrollY : 50;
      };
      window.addEventListener("scroll", updateScrollDirection); // add event listener
      return () => {
        window.removeEventListener("scroll", updateScrollDirection); // clean up
      }
    }, [scrollDirection]);
  
    return scrollDirection;
  };


function Slider({title, description, colorLight, color, children, link, buttonType, backButton, id}) {
    const scrollDirection = useScrollDirection();
    const location = useLocation();
    return (
        <div id={id} style={{'--rgb-color-light':colorLight, '--rgb-color': color, }} className={backButton ? "special slider" : "slider"}>
          <div className="funtenjer">
            <div className="grid">
                <div className="about">
                    {backButton && <div className={`${ scrollDirection === "down" ? "hidden" : "visible"} slide-left`}><Button link={"/"} type="back"> <TbArrowBackUp/>Jump back</Button></div>}
                    <h2>{title}</h2>
                    <p>{description}</p>
                    {location.pathname === "/" && (
                        <div className='button-holder'>
                            <Button
                                link={link}
                                type={buttonType}>
                                Learn more
                            </Button>
                        </div>
                    )}

                </div>
                <div className="mockup">
                    {children}
                </div>
            </div>
            </div>
        </div>    
    )
}

export default Slider