import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { motion as m } from "framer-motion";

// CSS
import "./components/stacks/stacks.css";

// components
import ProjectFeatures from "./components/projectFeatures/ProjectFeatures";
import TechnologyStack from "./components/technologyStack/TechnologyStack";
import ProjectTeam from "./components/projectTeam/ProjectTeam";
import AboutProject from "./components/aboutProject/AboutProject";
import ProjectInfo from "./components/projectInfo/ProjectInfo";
import Slider from "./components/slider/Slider";
import NanodegreeMockup from "./components/nanoDegreeProgramMockup/NanoDegreeProgramMockup";
import ProjectResult from "./components/projectResult/ProjectResult";
import Contact from "./components/contact/Contact";
import Footer from "./components/footer/Footer";

import pageContent from "./content"

const NanodegreeProgramProject = () => {
  const { pathname } = useLocation();
  const { aboutProject, projectFeatures, teamMembers, slider, projectInfo, projectResult, technologies} = pageContent.nanodegreeProgram; // Importing from content

  useEffect(() => {
      document.documentElement.scrollTop = 0;
  }, [pathname]);

  return (<m.div style={{overflow: "hidden"}} initial={{opacity: 0}} animate={{opacity: 1}} transition={{durtation:1, ease: "easeOut"}} exit={{opacity: 0}}>
    <style>
      {`
        html {
          --project-color-rgb: var(--primary-color-rgb);
        }
      `}
    </style>
      <div className="slider-holder">
      <Slider {...slider}>
        <NanodegreeMockup />
        </Slider>
      </div>
    <div className="funtenjer">
        <ProjectInfo {...projectInfo} />
        <AboutProject {...aboutProject}/>
        <ProjectTeam teamMembers={teamMembers}/>
        <TechnologyStack technologies={technologies}/>
    </div>
    <ProjectFeatures {...projectFeatures}/>
    <ProjectResult {...projectResult}/>
    <Contact/>
    <Footer/>
    </m.div>
  )
}

export default NanodegreeProgramProject;