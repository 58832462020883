import './projectTeam.css';
import React, { useState } from "react";

const ProjectTeam = ({teamMembers}) => {
  return (
    <section className='project-team'>
        <div className='title'>
            <h2>Team</h2>
        </div>
        <div className='team'>
            {teamMembers.map((member, index)=>(
                <div className='member' key={index}>{member}</div>
            ))}
        </div>
    </section>
  )
}

export default ProjectTeam;