import eLibraryHomeImg from "./assets/eLibrary/template/home.png";
import eLibraryBooksImg from "./assets/eLibrary/template/books.png";
import eLibraryNotesImg from "./assets/eLibrary/template/notes.png";
import eLibraryFinishedBooksImg from "./assets/eLibrary/template/finishedBooks.png";
import eLibraryLoginImg from "./assets/eLibrary/template/login.png";
import eLibraryReaderImg from "./assets/eLibrary/template/reader.png";
import eLibraryAudioPlayerImg from "./assets/eLibrary/template/audioPlayer.png";
import eLibraryRegisterImg from "./assets/eLibrary/template/register.png";
import eLibraryBookPreviewImg from "./assets/eLibrary/template/bookPreview.png";

import eLibraryHomeImgMin from "./assets/eLibrary/template/homeMin.webp";
import eLibraryBooksImgMin from "./assets/eLibrary/template/booksMin.webp";
import eLibraryNotesImgMin from "./assets/eLibrary/template/notesMin.webp";
import eLibraryFinishedBooksImgMin from "./assets/eLibrary/template/finishedBooksMin.webp";
import eLibraryLoginImgMin from "./assets/eLibrary/template/loginMin.webp";
import eLibraryReaderImgMin from "./assets/eLibrary/template/readerMin.webp";
import eLibraryAudioPlayerImgMin from "./assets/eLibrary/template/audioPlayerMin.webp";
import eLibraryRegisterImgMin from "./assets/eLibrary/template/registerMin.webp";
import eLibraryBookPreviewImgMin from "./assets/eLibrary/template/bookPreviewMin.webp";

import nanodegreeProgramHome from "./assets/nanodegree/template/home.jpg";
import nanodegreeProgramVideo from "./assets/nanodegree/template/video.jpg";
import nanodegreeProgramQuiz from "./assets/nanodegree/template/quiz.jpg";
import nanodegreeProgramNotification from "./assets/nanodegree/template/notification.jpg";
import nanodegreeProgramRegistration from "./assets/nanodegree/template/registration.jpg";
import nanodegreeProgramContentManagement from "./assets/nanodegree/template/contentmanagement.jpg";

import nanodegreeProgramHomeMin from "./assets/nanodegree/template/home-min.webp";
import nanodegreeProgramVideoMin from "./assets/nanodegree/template/video-min.webp";
import nanodegreeProgramQuizMin from "./assets/nanodegree/template/quiz-min.webp";
import nanodegreeProgramNotificationMin from "./assets/nanodegree/template/notification-min.webp";
import nanodegreeProgramRegistrationMin from "./assets/nanodegree/template/registration-min.webp";
import nanodegreeProgramContentManagementMin from "./assets/nanodegree/template/contentmanagement-min.webp";

import nanodegreeBackground from "./assets/nanodegree/background.png";
import nanodegreeBackgroundMin from "./assets/nanodegree/background-min.webp";

import eLibraryBackground from "./assets/eLibrary/background.png";
import eLibraryBackgroundMin from "./assets/eLibrary/background-min.webp";


// Social icons on about
import { FaLinkedinIn, FaInstagram } from "react-icons/fa";

// Technology stacks icons
import { SiNodedotjs, SiHtml5, SiDocker, SiMysql, SiAmazonaws, SiStripe, SiPaypal, SiBackblaze, SiElasticsearch} from "react-icons/si";

// stuff

import ekrem from "./assets/stuff/EKREM.png";
import furkan from "./assets/stuff/FURKAN.png";
import ajdin from "./assets/stuff/AJDIN.png";
import vehid from "./assets/stuff/VEHID.png";

const config = {

  coreComponents:{
    contact:{
      title: "Any doubts? We're here to clarify",
      paragraph: "Email us and we'll get you to the right person.",
      email: "rouzel.ltd@gmail.com",
      address: "	5 St. John's Lane London EC1M 4BH"
    },
    footer:{
      facebook: "https://www.youtube.com/",
      instagram: "",
      linkedIn: ""
    }
  },
  landingpage:{
    hero:{
      title:"Your Growth Engine: Superior Software.",
      paragraph: "We Build, You Grow - Software Solutions for Progressive Businesses.",
      callToAction:"See Our Services",
      words: ['radiance', 'radiant', 'rapid', 'rapport', 'raptureous', 'raptureously', 'rapturous', 'rapturously', 'rational', 'razor-sharp', 'reachable', 'readable', 'readily', 'ready', 'reaffirm', 'reaffirmation', 'realistic', 'realizable', 'reasonable', 'reasoned', 'reassurance', 'receptive', 'reclaim', 'recommended', 'reconcile', 'reconciliation', 'record-setting', 'recovery', 'rectification', 'redeem', 'redemption', 'refined', 'refinement', 'reforming', 'refreshing', 'refund', 'regal', 'regard', 'rejoice', 'rejuvenate', 'relaxed', 'relent', 'reliable', 'relief', 'relish', 'remarkable', 'remedy', 'remission', 'remunerate', 'renaissance', 'renewed', 'renown', 'replaceable', 'reputation', 'resilient', 'resolute', 'resound', 'resourceful', 'respect', 'respite', 'resplendent', 'responsibly', 'responsive', 'restful', 'restored', 'restructure', 'retractable', 'revel', 'revelation', 'revere', 'reverence', 'reverent', 'reverently', 'revitalize', 'revival', 'revive', 'revives', 'revolutionary', 'reward', 'rewarding', 'rewardingly', 'rich', 'richer', 'richly', 'richness', 'right', 'righten', 'righteous', 'rightful', 'rightly', 'rightness', 'risk-free', 'robust', 'rock-star', 'rock-stars', 'rockstar', 'rockstars', 'romantic', 'romantically', 'romanticize', 'roomier', 'roomy', 'rosy']
    },
    stacks:{
      eLibraySlider: {
        title:"eLibrary",
        description:"eLibrary, our game-changing publishing software, enables online book sales in ePub or audiobook formats, expanding your revenue. Offering readers comprehensive customization including note-making, highlighting, and book-wide search, it's an innovative tool for an engaging digital reading experience.",
        colorLight:"var(--secondary-color-light-rgb)",
        color: "var(--primary-color-rgb)",
        buttonType:"secondary",
        link:"/eLibrary"
      },
      nanoDegreeSlider: {
        title:"Nanodegree Platform",
        description:"This online platform enables individuals or organizations to create diverse educational content with video, text, and audio formats. Offering integrated quizzes, file-sharing, and a robust content management system, it's an ideal choice for managing digital education.",
        colorLight:"var(--primary-color-light-rgb)",
        color: "var(--secondary-color-rgb)",
        buttonType:"primary",
        link:"/nanodegreeProgram"
      }
    }
  },
    eLibrary: {
      slider: {
        title: "eLibrary",
        description: "eLibrary, our game-changing publishing software, enables online book sales in ePub or audiobook formats, expanding your revenue. Offering readers comprehensive customization including note-making, highlighting, and book-wide search, it's an innovative tool for an engaging digital reading experience.",
        colorLight: "var(--secondary-color-light-rgb)",
        color: "var(--primary-color-rgb)",
        buttonType: "secondary",
        link: "/eLibrary/",
        backButton: true
      },
      projectInfo: {
        time: "6 months + 10 years regular updates",
        type: "Web Platform",
        industry: "Digital Publishing",
        platform: "Web, Responsive for mobile"
      },
      aboutProject: {
        ideas: [
          { 
            title: 'Reader', 
            description: 'An adjustable and customizable e-reader, that empowers users to fully dictate the appearance of their reading page.' 
          },
          {
            title: 'Audio Book',
            description: ' Comprehensive audio book reading system that covers all aspects of the listening experience.'
          },
          {
            title: 'CMS',
            description: 'Our robust content management system (CMS) empowers publishers with full control over every single aspect of the platform.'
          }
        ],
        shortDescriptoin: "This platform is designed to empower publishing houses with advanced tools, enabling them to enhance their offerings and stimulate business revenue. It opens up new avenues for their reader base, further solidifying their market position."
      },
      teamMembers: [
        "Illustrator",
        "UX/UI designer",
        "Project manager",
        "Content manager",
        "Marketer",
        "Backend Developer",
        // "IOS Developer",
        // "Android Developer"
      ],
      projectFeatures: {
        features:[
          {
            title: "Books",
            description: "A comprehensive page featuring books categorized and filtered by their format and genres.",
            img: eLibraryBooksImg,
            minImg: eLibraryBooksImgMin
          },
          {
            title: "Notes",
            description: "A centralized hub where users can access and organize all their notes and highlights from various books. The color-coded system allows users to easily distinguish and categorize their notes based on different topics or themes.",
            img: eLibraryNotesImg,
            minImg: eLibraryNotesImgMin
          },
          {
            title: "Finished Books",
            description: "A comprehensive list showcasing all the books a user has read, including the dates they started and finished. Additionally, the list includes ratings provided by the user for each book.",
            img: eLibraryFinishedBooksImg,
            minImg: eLibraryFinishedBooksImgMin
          },
          {
            title: "Log in",
            description: "A standardized login system, offering users the convenience of logging in using their Google or Facebook accounts.",
            img: eLibraryLoginImg,
            minImg: eLibraryLoginImgMin
          },
          {
            title: "Reader",
            description: "An adaptable and customizable reading interface that empowers users with full control over the appearance of their reading page. The system also includes comprehensive note-taking and highlighting functionalities, along with the ability to perform a comprehensive search throughout the entire book.",
            img: eLibraryReaderImg,
            minImg: eLibraryReaderImgMin
            
          },
          {
            title: "Audio Book",
            description: "An audio book listening page equipped with all the standard functionalities of an audio player. In addition to the core features, it offers a sleeping mode for a more convenient listening experience, as well as seamless note-taking while enjoying the audio book.",
            img: eLibraryAudioPlayerImg,
            minImg: eLibraryAudioPlayerImgMin
          },
          {
            title: "Payment System",
            description: "Our platform features a secure and versatile payment system that supports multiple payment methods with robust encryption and reliable security measures.",
            img: eLibraryRegisterImg,
            minImg: eLibraryRegisterImgMin
          },
          {
            title: "Book Description",
            description: "Users can read book descriptions and view reviews to gain a better understanding of each book. They have the option to add books to their Wishlist or choose to read or listen to them.",
            img: eLibraryBookPreviewImg,
            minImg: eLibraryBookPreviewImgMin
          }
        ],
          featuresDescription: "Unlocking Exceptional Capabilities for Your Success",
          background: eLibraryBackground,
          minBackground: eLibraryBackgroundMin
      },
    projectResult: {
      description: "A harmoniously balanced platform that provides publishing houses with a comprehensive suite of tools to enhance their customer offerings.",
      img: eLibraryHomeImg,
      minImg: eLibraryHomeImgMin
    },
    technologies: [
      {title: "Backend", name:"Node.js", ico:<SiNodedotjs/>},
      {title: "Frontend", name:"HTML5, CSS3, and JavaScript ES6+", ico:<SiHtml5/>},
      {title: "Database", name:"Mysql", ico:<SiMysql/>},
      {title: "Deployment", name:"Docker", ico:<SiDocker/>},
      {title: "Payment Processing", name:"Stripe", ico:<SiStripe/>},
      {title: "Cloud Storage", name:"Backblaze", ico:<SiBackblaze/>},
      {title: "Cloud Computing", name:"Amazon Web Services (AWS)", ico:<SiAmazonaws/>},
      {title: "Search and Analytics", name:"Elasticsearch", ico:<SiElasticsearch/>}
      ]
    },
    nanodegreeProgram: {
      aboutProject: {
        ideas: [
          { 
            title: 'Immersive Learning Experience', 
            description: 'Immerse learners in an interactive and dynamic educational journey, leveraging multimedia content, interactive quizzes, and engaging assignments to foster deep comprehension and knowledge retention.' 
          },
          {
            title: 'Progress Tracking and Evaluation',
            description: 'Enable comprehensive progress tracking and evaluation through advanced assessment tools, allowing organizations to monitor learners development, measure their performance, and provide valuable feedback for continuous improvement.'
          },
          {
            title: 'Flexible Content Management',
            description: 'Empower organizations to effortlessly create, manage, and customize their own curriculum using our intuitive content management system (CMS), allowing them to easily organize learning materials, add or update content, and tailor the educational experience to their specific needs and preferences.'
          }
        ],
        shortDescriptoin: "Our Nanodegree Program Website Platform is a powerful software solution designed to empower organizations, institutions, and companies to seamlessly implement their own curated curriculums and educational content. By leveraging our platform, they can efficiently create, manage, and deliver engaging learning experiences tailored to their specific needs. With our user-friendly interface and robust features, organizations can take full control of their digital education initiatives and unlock the potential for enhanced learning outcomes."
      },
      projectFeatures: {
        features:[
          {
            title: "Video Content",
            description: "Enrich the learning journey with high-quality video lectures and instructional content that brings concepts to life, fostering engagement and understanding among learners.",
            img: nanodegreeProgramVideo,
            minImg: nanodegreeProgramVideoMin
          },
          {
            title: "Interactive Quizzing",
            description: "Engage learners with interactive quizzes that include a variety of question formats, fostering active participation and reinforcing knowledge retention.",
            img: nanodegreeProgramQuiz,
            minImg: nanodegreeProgramQuizMin
          },
          {
            title: "Project Submissions",
            description: "Foster hands-on learning and practical application of skills by providing a platform for learners to showcase their projects, receive constructive feedback, and demonstrate their proficiency in real-world scenarios.",
            img: nanodegreeProgramNotification,
            minImg: nanodegreeProgramNotificationMin
          },
          {
            title: "User-Friendly System Integration",
            description: "Benefit from a seamless system integration that offers user-friendly sign-in, effortless account setup, and convenient payment options. Simplify the onboarding process and empower users to easily navigate, select packages, and embark on their learning journey smoothly.",
            img: nanodegreeProgramRegistration,
            minImg: nanodegreeProgramRegistrationMin
          },
          {
            title: "Intuitive Content Management",
            description: "Utilize an intuitive content management system (CMS) that simplifies the organization and management of learning materials. Effortlessly upload, update, and categorize educational content, ensuring easy navigation and a seamless learning experience for both administrators and learners.",
            img: nanodegreeProgramContentManagement,
            minImg: nanodegreeProgramContentManagementMin
          }
        ],
          featuresDescription: "Unleash a powerful array of features that fuel immersive learning experiences, driving remarkable educational outcomes.",
          background: nanodegreeBackground,
          minBackground: nanodegreeBackgroundMin
      },

      teamMembers: [
        "Illustrator",
        "UX/UI designer",
        "Project manager",
        "Content manager",
        "Marketer",
        "Backend Developer",
        // "IOS Developer",
        // "Android Developer"
      ],
      slider: {
        title: "Nanodegree program",
        description: "This online platform enables individuals or organizations to create diverse educational content with video, text, and audio formats. Offering integrated quizzes, file-sharing, and a robust content management system, it's an ideal choice for managing digital education.",
        colorLight: "var(--primary-color-light-rgb)",
        color: "var(--secondary-color-rgb)",
        buttonType: "secondary",
        link: "/eLibrary/",
        backButton: true
      },
      projectInfo: {
        time: "3 months",
        type: "Web Platform",
        industry: "Online Education",
        platform: "Web, Responsive for mobile"
      },
    projectResult: {
      description: "Unlocking transformative outcomes with this platform, seamlessly integrating powerful features for organizations and their end-users. Drive engagement, knowledge retention, and tangible growth as learners acquire new skills and achieve exceptional results.",
      img: nanodegreeProgramHome,
      minImg: nanodegreeProgramHomeMin
    },
    technologies: [
      {title: "Backend", name: "Node.js", ico: <SiNodedotjs/>},
      {title: "Frontend", name: "HTML5, CSS3, and JavaScript ES6+", ico: <SiHtml5/>},
      {title: "Relational Database System", name: "MySQL", ico: <SiMysql/>},
      {title: "Online Payment Gateway", name: "PayPal API", ico: <SiPaypal/>},
      {title: "Cloud Object Storage", name: "Amazon S3 Bucket", ico: <SiAmazonaws/>},
      {title: "Cloud Service Provider", name: "Amazon Web Services (AWS)", ico: <SiAmazonaws/>},
    ]
    },
    about:{
      ourMission:"Rouzel architects innovative systems, engineered to propel businesses towards significant revenue enhancement. Our motto, 'We build, You Grow', summarizes our commitment to driving client success through our software solutions.",
      weAreRouzel:"Meet the dynamic leadership behind Rouzel. Our dedicated team, driven by a shared passion for technology, is committed to propelling your business to new heights.",
      people:[
        {
          name: "Vehid Višća",
          title: "COO",
          image: vehid,
          // socials: [{ico: FaLinkedinIn, link:"www.google.com"}, {ico: FaInstagram, link:"www.google.com"}],
          socials: []
        },
        {
          name: "Ajdin Karić",
          title: "CMO",
          image: ajdin,
          // socials: [{ico: FaLinkedinIn, link:"www.google.com"}, {ico: FaInstagram, link:"www.google.com"}],
          socials: []
        },
        {
          name: "Furkan Nurović",
          title: "CTO",
          image: furkan,
          // socials: [{ico: FaLinkedinIn, link:"www.google.com"}, {ico: FaInstagram, link:"www.google.com"}],
          socials: []
        },
        {
          name: "Ekrem Nurović",
          title: "CEO",
          image: ekrem,
          // socials: [{ico: FaLinkedinIn, link:"www.google.com"}, {ico: FaInstagram, link:"www.google.com"}],
          socials: []
        },
      ]
    }
    // Add more projects here...
  };
  
  export default config;