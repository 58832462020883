import "./css/main.css"
import "./css/pageNotFound.css"

import {motion as m} from "framer-motion"

import Header from './components/header/Header';
import Footer from './components/footer/Footer';

import Button from './components/button/Button'
import Marquee from "react-fast-marquee";

import badBoy from './assets/BadBoy.svg'

function PageNotFound() {
  const words = ["We'd love to show you around," , "but this page seems to be on a permanent vacation.", "Maybe it's off sipping margaritas on a tropical island somewhere,", "who knows?", "In any case,", "we apologize for the inconvenience.", "Please try one of our other pages and if you see this page again,", "please send a search party.", "Thank you for your understanding!"]
  return (
        <m.div  initial={{opacity: 0}} animate={{opacity: 1}} transition={{durtation:1, ease: "easeOut"}} exit={{opacity: 0}}>
          <Header/>
          <section className="page-not-found">
            <div className="funtenjer">
                <div className="content">
                    <div>
                        <h2>Oops, looks like you've wandered into the void.</h2>
                        <p>We strongly recommend that you go back home.</p>  
                        <Button className='btn' type="primary-light" link="/">Go back</Button>      
                    </div>
                    <div className="img-holder">
                        <img className='main-image' src={badBoy} alt=""/>
                    </div>
                </div>
                </div> 
                <div className="roll-content">
                <Marquee delay={6} speed={40} loop={0} gradient={false}>
                  {words.map((word, index) => (
                    <Button key={index} type="secondary-light">{word}</Button>
                ))}
                </Marquee>
                </div>
          </section>
          <Footer/>
          </m.div>
  );
}

export default PageNotFound;
