import "./css/main.css"
import "./css/about.css"

import {motion as m} from "framer-motion";

import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import Contact from './components/contact/Contact';

import line from "./assets/aboutLine.png";

import { ParallaxProvider, Parallax } from 'react-scroll-parallax';

import pageContent from "./content";

function Person({index, name, title, image, socials }) {
  return (
    <div style={{marginTop:`${index * -3}em`}} className="person">
      <div className="img-holder">
        <img src={image} alt={name} />
      </div>
      <div className="info">
        <div className="name">{name}</div>
        <div className="title">{title}</div>
        <div className="socials">
        {socials.map((social, index) => {
          const Icon = social.ico;
          return (
            <a key={index} href={social.link} target="_blank" rel="noreferrer" className="ico">
              <Icon/>
            </a>
          )}
        )}
        </div>
      </div>
    </div>
  );
}


function About() {
  const {people, ourMission, weAreRouzel} = pageContent.about; // Importing from content

  return (
        <m.div className="about-page"  initial={{opacity: 0}} animate={{opacity: 1}} transition={{durtation:1, ease: "easeOut"}} exit={{opacity: 0}}>
          <ParallaxProvider>
          <Header/>
            <div className="funtenjer">
              <section className="about-hero">
                <h3>We are breaking free from the</h3>
                <h1>Main<span>stream</span></h1>
                <Parallax speed={5}>
                  <img src={line} alt="" className="line"/>
                </Parallax>
              </section>
              <Parallax speed={3}>
                <section id="our-mission" className="company-mission">
                  <h2><span>Our</span><span>Mission</span></h2>
                    <p>{ourMission}</p>
                </section>
              </Parallax>
                <section id="we-are-rouzel" className="we-are">
                  <h2>We are Rouzel</h2>
                  <p>{weAreRouzel}</p>
                  <div className="grid">
                  {people.map((person, index) => (
                    <Person key={index} index={index} {...person} />
                  ))}
                  </div>
                </section>
            </div> 
            <Contact/>
          <Footer/>
          </ParallaxProvider>
          </m.div>
  );
}

export default About;
