import React from 'react'
import classNames from 'classnames'

//images
import nanodegreeProgramHome from '../../assets/nanodegree/bluePrints/home.svg';
import nanodegreeProgramQuiz from '../../assets/nanodegree/bluePrints/quiz.svg';
import nanodegreeProgramVideo from '../../assets/nanodegree/bluePrints/video.svg';
import nanodegreeProgramProject from '../../assets/nanodegree/bluePrints/project.svg'


import { useEffect, useState } from 'react'
// icons
import { TbHome } from 'react-icons/tb'
import { TbPlayerPlay } from 'react-icons/tb'
import { TbQuestionMark } from 'react-icons/tb'
import { TbSend } from 'react-icons/tb'

const NanoDegreeProgram = () => {

  const [activeMockupTab, setActiveMockupTab] = useState(0);
  const [toFadeOut, setToFadeOut] = useState(false);

  const fadeAnimationDuration = 0.5; // seconds
  const autoIncrementMockupsInterval = 9000; // milliseconds

  const fadeAndThenSetActiveMockupTab = function(index){
    clearTimeout(currentAutoIncrementIntervalId);
    setToFadeOut(true);
    setTimeout(() => {  
      setActiveMockupTab(index);
      setToFadeOut(false);
    }, fadeAnimationDuration*1000);
  };

  const mockupTabs = [
    { icon: TbHome, mockupImage: nanodegreeProgramHome},
    { icon: TbPlayerPlay, mockupImage: nanodegreeProgramVideo },
    { icon: TbQuestionMark, mockupImage: nanodegreeProgramQuiz },
    { icon: TbSend, mockupImage: nanodegreeProgramProject },
  ];

  let currentAutoIncrementIntervalId = null;

  function removeAutoIncrementInterval(){
    clearTimeout(currentAutoIncrementIntervalId);
  }

  function resetAutoIncrementInterval() {
    const currentTimeoutId = currentAutoIncrementIntervalId = setTimeout(() => {
      fadeAndThenSetActiveMockupTab((activeMockupTab + 1) % mockupTabs.length);
    }, autoIncrementMockupsInterval);
    return () => clearTimeout(currentTimeoutId);
  }

  useEffect( resetAutoIncrementInterval , [activeMockupTab]);

  return (
    <div className='img-holder'>
      {/* <div className="note-holder"> */}
        {/* <Note highlighted={"The greatest victory is that which requires no battle."}
          color={"var(--primary-color-light)"}
          bookName={"Art of war"}
          bookAuthor={"San Tzu"} 
        /> */}
      {/* </div> */}
      <img
        className={classNames('mockup-image', { fadein: !toFadeOut })}
        src={mockupTabs[activeMockupTab].mockupImage}
        style={{ "--fade-animation-duration": fadeAnimationDuration+'s' }}
        alt="mockup" />
      <div className="nav">
        { mockupTabs.map((tab, index) => (
          <div 
            className={ classNames('ico', {
                active: index === activeMockupTab
            })}
            key={index}
            onClick={() => fadeAndThenSetActiveMockupTab(index)}>
              <tab.icon />
          </div>
        )) }
        </div>
    </div>
  )
}

export default NanoDegreeProgram