import './stacks.css'
import Slider from '../slider/Slider'
import ELibraryMockup from '../eLibraryMockup/ELibraryMockup'
import NanoDegreeProgramMockup from '../nanoDegreeProgramMockup/NanoDegreeProgramMockup'

import React from 'react'

function Stacks({eLibraySlider, nanoDegreeSlider}) {
  return (
    <section className={'stack'}>
        <div className="slider-holder">
          <Slider id="eLibrary" {...eLibraySlider} >
              <ELibraryMockup/>
          </Slider>
          <Slider id="nanodegreeProgram" {...nanoDegreeSlider}>
              <NanoDegreeProgramMockup/>
          </Slider>
        </div>
    </section>
  )
}

export default Stacks