import "./css/main.css"

import Header from './components/header/Header';
import Hero from './components/hero/Hero';
import Stacks from './components/stacks/Stacks';
import Contact from './components/contact/Contact';
import Footer from './components/footer/Footer';
import CookieBanner from './components/cookieBanner/CookieBanner';

import {motion as m} from 'framer-motion';
import { ParallaxProvider, Parallax } from 'react-scroll-parallax';


import pageContent from "./content";


function Home() {
  const {hero, stacks} = pageContent.landingpage; // Importing from content

  return (
    <m.div initial={{opacity: 0}} animate={{opacity: 1}} transition={{durtation:5, ease: "easeOut"}} exit={{opacity: 0}}>
      <ParallaxProvider>
        <Header/>
        <CookieBanner/>
        <Hero {...hero} />
        <Parallax easing='easeOutQuad' speed={14}>
          <Stacks {...stacks}/>
        </Parallax>
        <Contact/>
        <Footer/>
      </ParallaxProvider>
    </m.div>
  );
}

export default Home;
