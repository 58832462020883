
import Home from './Home'
import NanodegreeProgramProject from './NanodegreeProgramProject';
import ELibraryProject from './ELibraryProject';
import PageNotFound from './PageNotFound';
import About from './About';
import PrivacyPolicy from './PrivacyPolicy'

import {Routes, Route, useLocation} from 'react-router-dom';


import {AnimatePresence} from "framer-motion"

function App() {
  const location = useLocation();
  return (
    <AnimatePresence mode='wait'>
      <Routes location={location} key={location.pathname}>
        <Route path="/" element={<Home/>} />
        <Route key={'/eLibrary'} path="/eLibrary" element={<ELibraryProject/>} />
        <Route path="/nanodegreeProgram" element={<NanodegreeProgramProject/>} />
        <Route path="/about" element={<About/>} />
        <Route path="/*" element={<PageNotFound/>} />
        <Route path="/privacyPolicy" element={<PrivacyPolicy/>} />
      </Routes>
    </AnimatePresence>
  );
}

export default App;
